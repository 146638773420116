import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TablePagination, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button, Avatar } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplayAppIcon from '@material-ui/icons/Replay';

import LuxonUtils from '@date-io/luxon';

import { DateTime } from "luxon";
import { GetImagePath } from '.././Utils';

import { TimeContext } from "../store/TimeProvider";
import { ShopsContext } from '../store/ShopsProvider';
import { UsersContext } from '../store/UsersProvider';
import { ShakiContext } from '../store/ShakiProvider';


function Edi(data) {

	const classes = useStyles();

	const { setOpenSnackBar, getRemoteConf } = useContext(ShakiContext);
	const { day_prod } = useContext(TimeContext);
	const { checkUserRights } = useContext(UsersContext);
	const { shops } = useContext(ShopsContext);

	const [shops_ean, setShopsEan] = useState({});
	const [list, setList] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);

	const [edi_config, setEdiConfig] = useState(null);


	const status = {
		'fetch_data': '⚪️',
		'fetch_attachment': '⚪️',
		'ready_to_parse' : '⚪️',
		'parsing_success': '🟢',
		'parsing_failed': '🔴'
	};


	useEffect(() => {
		setEdiConfig(getRemoteConf('edi_config'));
	}, []);


	useEffect(() => {

		if (edi_config === null) return;
		if (shops === null) return;

		var map = {};
		for (var shop_id in shops) {
			if (shops[shop_id].ean13 === undefined) continue;
			map[shops[shop_id].ean13] = shops[shop_id];
		};

		setShopsEan(map);

	}, [shops, edi_config]);


	useEffect(() => {

		if (Object.values(shops_ean).length === 0) return;

		return firebase.firestore().collection('edi_messages').orderBy('timestamp', 'desc').limit(300).onSnapshot((querySnapshot) => {

			if (querySnapshot === null) return;

			var list = [];
			querySnapshot.forEach((doc) => {
				var data = doc.data();
				list.push(data);
			});

			setList(list);
		});

	}, [shops_ean]);


	const replay = (id) => {
		return firebase.firestore().collection('edi_messages').doc(id).update({ action: 'ready_to_parse' });
	};


	const copy = (text) => {
		text = text.replaceAll("'", '\n');
		return navigator.clipboard.writeText(text).then(() => {
			setOpenSnackBar('copié !')
		});
	};


	const warehouseLookup = (data) => {

		var lines = data.split("'");
		var ret = [];

		for (var line of lines) {

			if (line.search('NAD') !== 0) continue;
			var search = line.match(/NAD\+DP\+([0-9]*)/);

			if (search === null || search.length === 0) continue;

			if (shops_ean[search[1]] === undefined){
				ret.push({ name: search[1] })
				continue;
			}

			ret.push(shops_ean[search[1]]);
		};

		return ret;
	};


	const deliveryDateLookup = (data, warehouse) => {

		var lines = data.split("'");
		var ret = [];

		for (var line of lines) {

			var search = line.match(/DTM\+[0-9]{1}:([0-9]{8}|[0-9]{12}):/);
			if (search === null || search.length === 0) continue;

			var date = DateTime.fromObject({ year: parseInt(search[1].substr(0, 4)), month: parseInt(search[1].substr(4, 2)), day: parseInt(search[1].substr(6, 2)) });

			if (edi_config.order_minus_days !== undefined && edi_config.order_minus_days[warehouse.id] !== undefined) {
				date = date.minus({ days: edi_config.order_minus_days[warehouse.id] });
			};

			ret.push(date)
		};

		return ret;
	};


	return (
		<div>

			<div style={{ padding: 20 }}>
				<Breadcrumbs aria-label="breadcrumb">
					<Link to='/'>Accueil</Link>
					<Typography>Commandes EDI</Typography>
				</Breadcrumbs>

				<h2>Commandes EDI</h2>
			</div>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell style={{ width: '25%' }}>Identifier</StyledTableCell>
							<StyledTableCell>Edi Reference</StyledTableCell>
							<StyledTableCell>Date de livraison attendue</StyledTableCell>
							<StyledTableCell>Plateforme</StyledTableCell>
							<StyledTableCell>Message reçu le</StyledTableCell>
							<StyledTableCell align="right">Statut</StyledTableCell>
							<StyledTableCell style={{ width: 100 }} align="right">Actions</StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>

						{list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
							console.log(row.id);

							var warehouse = warehouseLookup(row.attachmentContents[0]);
							return(
							<TableRow key={row.identifier} className={classes.tr}>

								<StyledTableCell component="th" scope="row">
									{row.identifier}
								</StyledTableCell>

								<StyledTableCell>
									{Array.isArray(row.edi_references) ?
										row.edi_references.map((row2) => (
											<>
											<Link key={row.identifier + '_' + row2} to={'/orders/' + row2}>{row2}</Link><br />
											</>
										))
									:
										<Link to={'/orders/' + row.edi_reference}>{row.edi_reference}</Link>
									}
								</StyledTableCell>

								<StyledTableCell>
									{deliveryDateLookup(row.attachmentContents[0]).map((row4) => (
										<>
											{row4.toFormat('dd/MM/yyyy')}<br/>
										</>
									))}
								</StyledTableCell>

								<StyledTableCell>
									{warehouse.map((row3) => (
										<>
										{row3.id !== undefined ?
											<>
											<Link to={'/shops/' + row3.id }>{row3.name}</Link><br />
											</>
											:
											<div>{row3.name}</div>
										}
										</>
									))}
								</StyledTableCell>

								<StyledTableCell>{DateTime.fromMillis(row.timestamp).setLocale('fr').toFormat('dd/LL/yyyy à HH:mm')}</StyledTableCell>
								<StyledTableCell align="right" style={{ fontSize: 18 }}>{status[row.action]}</StyledTableCell>
								<StyledTableCell align="right">

									<Button onClick={(e) => copy(row.attachmentContents[0])} color='primary' size='small' style={{ minWidth: 20 }} title={'Copier le message'}>
										<FileCopyIcon />
									</Button>

									<Button disabled={!checkUserRights('edi_replay') || day_prod > parseInt(DateTime.fromMillis(row.timestamp).toFormat('yyyyLLdd'))} onClick={(e) => replay(row.identifier)} color='primary' size='small' style={{ minWidth: 20 }} title={'Rejouer le message'}>
										<ReplayAppIcon />
									</Button>
								</StyledTableCell>

								</TableRow>
							)
						})}

					</TableBody>

				</Table>

			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[50, 100, 200]}
				component="div"
				count={list.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(event) => {
					setRowsPerPage(parseInt(event.target.value, 10));
					setPage(0);
				}}
			/>

		</div>
	);
}

export default React.memo(Edi);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);


const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	tr: {
		'&:nth-of-type(odd)': {
			backgroundColor: '#F5F5F5',
		}
	},
	tr_predictive: {
		'&:nth-of-type(2n+1)': {
			backgroundColor: '#EDF0E5',
		},
		'&:nth-of-type(2n)': {
			backgroundColor: '#DBE1CB',
		}
	}
});
