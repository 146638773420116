import React from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button, Breadcrumbs, Avatar} from '@material-ui/core';
import { Link, useParams } from "react-router-dom";
import Resizer from 'react-image-file-resizer';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';


import { UploadImage, GetImagePath } from '.././Utils';

import { ShakiContext } from '../store/ShakiProvider';
import { UsersContext } from '../store/UsersProvider';
import { ShopsContext } from '../store/ShopsProvider';

function User() {

	const { setOpenSnackBar, getRemoteConf } = useContext(ShakiContext);
	const { user_roles, users_hash, checkUserRights } = useContext(UsersContext);
	const { getProductionSites, getShops } = useContext(ShopsContext);

	var { id } = useParams();

	const [current_user, setCurrentUser] = useState(null)
	const [admin_users, setAdminUsers] = useState([])
	const [user_jobs, setUserJobs] = useState({});
	const [delivery_operators, setDeliveryOperators] = useState({});

	const [production_sites, setProductionSites] = useState([]);
	const [shops, setShops] = useState([]);

	const classes = useStyles();


	useEffect(() => {
		setUserJobs(getRemoteConf('user_jobs'));
		setDeliveryOperators(getRemoteConf('delivery_operators'));
	}, []);


	useEffect(() => {

		getProductionSites((list) => {
			setProductionSites(list);
		});

	}, []);


	useEffect(() => {

		getShops((list) => {
			setShops(list.sort((a, b) => a.name.localeCompare(b.name)));
		});

	}, []);


	useEffect(() => {

		if (production_sites.length === 0) return;
		if (shops.length === 0) return;

		return firebase.firestore().collection('users').doc(id).onSnapshot((doc) => {
			var data = doc.data();
			setCurrentUser(data);
		});

	}, [id, production_sites, shops]);


	useEffect(() => {

		// User admin
		var list = [];
		for (var i in users_hash) {
			if (users_hash[i].admin) list.push(users_hash[i]);
		};
		setAdminUsers(list);

	}, [users_hash]);


	if (current_user === null) return null;

	const title = ((current_user.first_name === '') ? 'Anonymous' : current_user.first_name + ' ' + current_user.last_name);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		current_user[name] = value;
		setCurrentUser({...current_user});
	};


	const handleRolesChange = (event) => {

		if (current_user.roles === undefined) current_user.roles = {};

		if (event.target.checked === false) {
			delete current_user.roles[event.target.name];
		} else {
			current_user.roles[event.target.name] = true;
		};

		setCurrentUser({ ...current_user});
	};


	const handleJobChange = (event) => {
		current_user.job = event.target.value;
		setCurrentUser({ ...current_user});
	};


	const handleEmployerChange = (event) => {
		current_user.employer = event.target.value;
		setCurrentUser({ ...current_user});
	};


	const handleShopChange = (event) => {
		current_user[event.target.name] = event.target.value;
		setCurrentUser({ ...current_user});
	};


	const handleDisabledChange = (event) => {
		current_user[event.target.name] = !event.target.checked;
		setCurrentUser({ ...current_user});
	};


	const handleAdminChange = (event) => {
		current_user[event.target.name] = event.target.checked;
		setCurrentUser({ ...current_user});
	};


	const handleProductionSitesChange = (data) => {
		current_user.production_sites = data;
		return setCurrentUser({ ...current_user});
	};


	const handleShopsOwnerChange = (data) => {

		current_user.shop_owner = (data.length > 0) ? data[0] : '';
		current_user.shops_owner = data;

		return setCurrentUser({ ...current_user });
	};


	const fileChangedHandler = event => {

		var fileInput = false
		if (event.target.files[0]) fileInput = true

		if (fileInput) {

			Resizer.imageFileResizer(event.target.files[0], 1000, 1000, 'JPEG', 90, 0, (file) => {

				UploadImage('users', 'main', current_user.id, file, (path) => {
					setOpenSnackBar('Photo uploadée');
				});

			}, 'file');
		};
	};


	const update = () => {
		firebase.firestore().collection('users').doc(current_user.id).update(current_user);
		return setOpenSnackBar('Enregisté');
	};


	return (
		<div style={{ padding: 20 }} >

			<Breadcrumbs aria-label="breadcrumb">
				<Link to='/'>Accueil</Link>
				{current_user.phone_number !== undefined ?
					<Link to='/users'>Utilisateurs</Link>
				:
					<Link to='/external_users'>Utilisateurs externes</Link>
				}
				<Typography>{title}</Typography>
			</Breadcrumbs>

			<h2 style={{ marginBottom: 40 }}>{title}</h2>

			<div style={{ width: 700 }}>

				<div style={{ marginBottom: 30 }}>

					<Typography variant="h6" noWrap>
						<b>Actif</b>
					</Typography>

					<FormControl component="fieldset">
						<FormGroup>
							<FormControlLabel
								control={<Switch checked={(current_user.disabled !== undefined) ? !current_user.disabled : true} onChange={handleDisabledChange} name='disabled' />}
							/>
						</FormGroup>
					</FormControl>
				</div>

				<div style={{ marginBottom: 40 }}>

					<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
						<b>Identité</b>
					</Typography>

					<div style={{ marginBottom: 10 }}>

						<div>
							<label htmlFor='picture'>
								<input id='picture' type="file" onChange={fileChangedHandler} style={{ display: 'none' }} />
								<Avatar src={GetImagePath(current_user.images, 'main')} className={classes.large} component="span" />
							</label>

						</div>

						<div style={{ display: 'flex', marginBottom: 20 }}>

							<TextField
								id="first_name"
								name='first_name'
								value={current_user.first_name}
								label='Prénom'
								onChange={handleInputChange}
								style={{ width: '50%' }}
							/>
							<TextField
								id="last_name"
								name='last_name'
								value={current_user.last_name}
								label='Nom'
								onChange={handleInputChange}
								style={{ width: '50%', marginLeft: 10 }}
							/>
						</div>

						{current_user.email === undefined &&
						<div style={{ display: 'flex', marginBottom: 20 }}>

							<TextField
								disabled={true}
								id="phone_number"
								name='phone_number'
								value={current_user.phone_number}
								label='Numéro de téléphone'
								style={{ width: '33%' }}
							/>

							<TextField
								id="card_number"
								name='card_number'
								value={current_user.card_number}
								label='Numéro de badge'
								onChange={handleInputChange}
								style={{ width: '33%', marginLeft: 10 }}
							/>

							<TextField
								id="pin_code"
								name='pin_code'
								value={current_user.pin_code}
								label='Code pin'
								onChange={handleInputChange}
								style={{ width: '33%', marginLeft: 10 }}
								/>
							</div>
						}

						{current_user.email !== undefined &&
						<div style={{ display: 'flex', marginBottom: 20 }}>
							<TextField
								id="email"
								name='email'
								value={current_user.email}
								label='Email'
								onChange={handleInputChange}
								style={{ width: '50%' }}
							/>
						</div>
						}
					</div>
				</div>


				{current_user.email !== undefined &&
					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap>
							<b>Points de vente associés</b>
						</Typography>

						{production_sites.length > 0 &&
							<div style={{ marginBottom: 20 }}>

								<Autocomplete
									style={{ width: '100%' }}
									multiple
									id='tags-standard'
									options={shops.map((item) => item.id)}
									defaultValue={current_user.shops_owner}
									getOptionLabel={(option) => shops.find((element) => element.id === option).name}
									getOptionSelected={(option, value) => option === value}

									onChange={(_, data) => { handleShopsOwnerChange(data) }}

									filterSelectedOptions={true}

									renderInput={(params) => (
										<TextField
											{...params}
											variant='standard'
											label=''
											placeholder="Choisissez un ou plusieurs points de vente"
										/>
									)}
								/>
							</div>
						}
					</div>
				}

				{current_user.phone_number !== undefined &&
				<div style={{ marginBottom: 40 }}>

					<Typography variant="h6" noWrap>
						<b>Sites de production</b>
					</Typography>

					{production_sites.length > 0 &&
						<div style={{ marginBottom: 20 }}>

							<Autocomplete
								style={{ width: '100%' }}
								multiple
								id='tags-standard'
								options={production_sites.map((item) => item.id)}
								defaultValue={current_user.production_sites}
								getOptionLabel={(option) => production_sites.find((element) => element.id === option).name}
								getOptionSelected={(option, value) => option === value}
								onChange={(_, data) => { handleProductionSitesChange(data) }}
								filterSelectedOptions={true}
								renderInput={(params) => (
									<TextField
										{...params}
										variant='standard'
										label=''
										placeholder="Choisissez un ou plusieurs sites de production"
									/>
								)}
							/>
						</div>
					}
				</div>
				}

				{current_user.phone_number !== undefined && (current_user.parent_user === undefined || current_user.parent_user === '') &&
				<div style={{ marginBottom: 30 }}>

					<Typography variant='h6' noWrap>
						<b>Administrateur</b>
					</Typography>

					<FormControl component='fieldset'>
						<FormGroup>
							<FormControlLabel
								control={<Switch checked={(current_user.admin !== undefined) ? current_user.admin : false} onChange={handleAdminChange} name='admin' />}
							/>
						</FormGroup>
					</FormControl>
				</div>
				}

				{current_user.phone_number !== undefined && (current_user.admin === undefined || current_user.admin === false) &&
				<div style={{ marginBottom: 40 }}>

					<Typography variant="h6" noWrap>
						<b>Compte parent</b>
					</Typography>

					<FormControl className={classes.formControl}>
						<InputLabel htmlFor="parent_user">Compte parent</InputLabel>
						<Select
							native
							value={current_user.parent_user}
							onChange={handleShopChange}
							inputProps={{
								name: 'parent_user',
								id: 'parent_user',
							}}
						>
							<option aria-label="None" value='' />
							{admin_users.sort((a, b) => {
								if (a.first_name < b.first_name) return -1;
								if (a.first_name > b.first_name) return 1;
								return 0;
							}).map((row) => (
								<option key={row.id} value={row.id}>{row.first_name} {row.last_name}</option>
							))}
						</Select>
					</FormControl>
				</div>
				}

				{current_user.phone_number !== undefined &&
				<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 40 }}>

					<div style={{ width: '50%' }}>

						<Typography variant="h6" noWrap>
							<b>Employeur</b>
						</Typography>

						<FormControl className={classes.formControl} style={{ width: '100%' }}>
							<InputLabel htmlFor="employer">Employeur</InputLabel>
							<Select
								native
								value={current_user.employer}
								onChange={handleEmployerChange}
								inputProps={{
									name: 'employer',
									id: 'employer',
								}}

							>
								<option aria-label='' value='' />
								<option value='Luna Food'>Luna Food</option>
								{Object.entries(delivery_operators).map((operator, i) => (
									<option value={operator[1].name}>{operator[1].name}</option>
								))}
							</Select>
						</FormControl>

					</div>

					<div style={{ width: '50%', marginLeft: 20 }}>

						<Typography variant="h6" noWrap>
							<b>Rôle</b>
						</Typography>

						<FormControl className={classes.formControl} style={{ width: '100%' }}>
							<InputLabel htmlFor="shop_owner">Rôle</InputLabel>
							<Select
								native
								value={current_user.job}
								onChange={handleJobChange}
								inputProps={{
									name: 'job',
									id: 'job',
								}}
							>
								<option aria-label='' value='' />
								{Object.entries(user_jobs).map((row, index) => (
									<option value={row[0]}>{row[1]}</option>
								))}
								{Object.entries(delivery_operators).map((operator, i) => (
									<option value={operator[0]}>Livreur {operator[1].name}</option>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
				}

				{current_user.phone_number !== undefined && (current_user.admin && checkUserRights('users_rights')) &&
				<div>
					<Typography variant="h6" noWrap><b>Droits</b></Typography>
					<FormControl component="fieldset">
						<FormGroup>
							{user_roles.map((row, index) => (
								<FormControlLabel
									key={row}
									control={<Switch checked={(current_user.roles !== undefined && current_user.roles[row] !== undefined) ? current_user.roles[row] : false} onChange={handleRolesChange} name={row} />}
									label={row}
								/>
							))}
						</FormGroup>
					</FormControl>
				</div>
				}

				{current_user.toto &&
				<div style={{ marginBottom: 40 }}>
					<Typography variant="h6" noWrap><b>Client associé : </b></Typography>
					<FormControl className={classes.formControl}>
						<InputLabel htmlFor="shop_owner">Clients</InputLabel>
						<Select
							native
							value={current_user.shop_owner}
							onChange={handleShopChange}
							inputProps={{
								name: 'shop_owner',
								id: 'shop_owner',
							}}
						>
							<option aria-label="None" value="" />
							{Object.values(shops).map((row) => (
								<option key={row.id} value={row.id}>{row.name}</option>
							))}
						</Select>
					</FormControl>
				</div>
				}

				<Button variant="outlined" color="default" onClick={(e) => {update()}}>
					Enregistrer
				</Button>

			</div>
		</div>
	);
}

export default React.memo(User);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	large: {
		width: 100,
		height: 100,
	},
});
