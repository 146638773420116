import React from 'react';

import { TextField, Divider, Typography } from '@material-ui/core';
import { Controller } from "react-hook-form";

import { ShakiContext } from "../store/ShakiProvider";
import { GetImagePath, GetPrice } from '.././Utils';

function ProductForm(data) {

	const { products_by_sku } = React.useContext(ShakiContext);

	return (
		<div style={{ display: "flex", flexDirection: "row", alignItems: 'center', padding: '0px 10px 15px 10px' }}>

			<div style={{ width: 120, height: 100, marginRight: 10, borderRadius: 10, boxShadow: 'rgb(0 0 0 / 20%) 1px 1px 7px 0px', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: 'url(' + GetImagePath(products_by_sku[data.sku].images, 'main') + ')' }}></div>

			<div style={{ width: '50%' }}>
				<Typography variant='caption'>
					{data.sku} | {products_by_sku[data.sku].ean13}
				</Typography>
				<Typography style={{ fontSize: 16 }}>
					{products_by_sku[data.sku].name}
				</Typography>
			</div>

			<div style={{ width: '20%' }}>
				<Controller as={TextField}
					control={data.control}
					name={'products.' + data.sku}
					label='Quantité'
					type='number'
					defaultValue={data.order.products[data.sku] === undefined ? 0 : data.order.products[data.sku]}
					InputProps={{
						inputProps: {
							min: 0
						}
					}}
					onChange={(e) => console.log(e)}
					style={{ width: 60 }}
				/>
			</div>

			<div style={{ width: '15%', textAlign: 'right' }}>
				<Typography style={{ fontSize: 14 }}>
					{GetPrice(data.rate_card, data.pricelist, null, data.sku, 1)}
				</Typography>
			</div>

			<Divider />

		</div>
	);
}

export default React.memo(ProductForm);
