import React, { useEffect, useState } from 'react';

import { getApp } from 'firebase/app';
import { getFirestore, collection, query, doc, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";


export const UsersContext = React.createContext({});

function UsersProvider({ children }) {

	const db = getFirestore(getApp());
	const auth = getAuth(getApp());

	const [status, setStatus] = useState(1);
	const [uid, setUid] = useState(null);
	const [user_token, setUserToken] = useState(null);

	const [user, setUser] = useState(null);

	const [users_hash, setUsersHash] = useState();
	const [users_childen, setUsersChildren] = useState([]);

	const user_roles = [
		'clients',
		'clients_write',
		'configuration',
		'edi',
		'edi_replay',
		'balances',
		'invoices',
		'invoices_create',
		'invoices_send',
		'invoices_regenerate',
		'invoices_delete',
		'order_preparation',
		'order_preparation_declare_missing',
		'planograms',
		'planograms_write',
		'pricelists',
		'pricelists_write',
		'production',
		'production_lines',
		'production_templates',
		'production_templates_write',
		'products',
		'products_write',
		'recipe_editor',
		'retail',
		'retail_products',
		'retail_products_write',
		'suppliers',
		'suppliers_inventory',
		'suppliers_articles_delete',
		'scripts',
		'shops',
		'shops_write',
		'stats',
		'tour',
		'unsolds',
		'unsolds_write',
		'external_users',
		'users',
		'users_rights'
	];


	const isShopOwner = () => {
		if (user === null) return false;
		if (user.shop_owner === undefined) return false;
		if (user.shop_owner === null) return false;
		return true;
	};


	const isAdmin = () => {
		if (user === null) return false;
		if (user.admin === false || user.admin === undefined) return false;
		return true;
	};


	/////////////////////
	const checkUserRights = (specific_right = null) => {

		if (user === null) return false;
		if (user.admin === false) return false;

		if (user.shop_owner !== undefined && user.shop_owner !== '') return true;
		if (user.shops_owner !== undefined && user.shops_owner.length > 0) return true;

		if (specific_right === null) return true;
		if (user.roles[specific_right] !== undefined && user.roles[specific_right] === true) return true;

		return false;
	};


	useEffect(() => {

		const subscriber = onAuthStateChanged(auth, (user) => {

			// Logged
			if (user) {

				auth.currentUser.getIdToken(true).then((idToken) => {
					setUserToken(idToken);
				});

				onSnapshot(doc(db, 'users', user.uid), (doc) => {

					var user = doc.data();
					user.uid = doc.id;

					setUser(user);
				});

			} else {

				setStatus(0);
				user = null;
			}

			setUid(user);
		});

		return subscriber; // unsubscribe on unmount

	}, []);


	const logout = () => {

		signOut(auth).then(() => {
			setStatus(0);
			setUid(null);
			setUser(null);
		});
	}


	useEffect(() => {

		if (uid === null) return;

		return onSnapshot(query(collection(db, 'users')), (querySnapshot) => {

			const list_hash = {};
			const children = [];

			querySnapshot.forEach((doc) => {

				var data = doc.data();
				data.uid = doc.id;
				list_hash[doc.id] = data;

				if (data.parent_user !== undefined && data.parent_user === uid) {
					children.push(data);
				}

			});

			setStatus(2);
			setUsersHash(list_hash);
			setUsersChildren(children);
		});

	}, [uid]);


	const createExternalUser = (email, onSuccess = () => { }, onError = () => { }) => {

		var f = getFunctions(getApp(), 'europe-west1')
		httpsCallable(f, 'users-createExternalUser')({
			email: email,
		}).then((response) => {
			onSuccess(response);
		}).catch((error) => {
			onError(error);
		});

		return true;
	};


	return(
		<UsersContext.Provider value={{
			user_roles: user_roles,

			user_token: user_token,
			user_status: status,
			user: user,

			users_hash: users_hash,
			users_childen: users_childen,

			logout: logout,
			checkUserRights: checkUserRights,
			isShopOwner: isShopOwner,
			isAdmin: isAdmin,

			createExternalUser: createExternalUser
		}}>
			{children}
		</UsersContext.Provider>
	);
}

export default React.memo(UsersProvider);
